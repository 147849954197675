<template>
    <div :class="!isWriterAccountDetails ? 'writers-details_item' : 'writers-account-details_item'">
        <div class="writers-details_item__avatar">
            <avatar
                :avatar-url="writer.avatar_url"
                :avatar-fallback="writer.avatar_fallback"
                :name="writer.name"
                :user="'writer'"
            />
            <span
                class="writers-details_item__avatar--status"
                :class="writer.is_online ? 'online' : 'offline'"
            />
        </div>
        <div class="writers-details_item__info">
            <div
                v-if="writer.is_trusted"
                class="mark_us_trusted"
            >
                Trusted
            </div>
            <p class="writers-details_item__name">
                {{ writer.name }}
                <span class="writers-details_item__id">
                    ID: {{ writer.id }}
                    <span
                        v-if="!writer.is_online && writer.last_seen_at"
                        class="writers-details_item__lastseen"
                    >
                        Last seen: {{ writer.last_seen_at | moment_from }} ago
                        <span
                            class="writers-details_item__lastseen-tooltip tooltip-zoom"
                            :data-tooltip-attribute="writer.last_seen | moment_full_text"
                        >
                            i
                        </span>
                    </span>
                </span>
            </p>
            <div class="writers-details_item__rating">
                <star-rating
                    :star-size="20"
                    :increment="0.1"
                    :rating="writer.statistics.rank_all / 2"
                    :active-color="themecolor"
                    :read-only="true"
                />
                <p class="writers-details_item__rating-count">
                    ({{ writer.statistics.rank_all_count | number }}) {{ writer.statistics.rank_all_count > 1 ? 'reviews' : 'review' }}
                </p>
            </div>
            <div
                v-if="writer.application.major"
                class="writers-details_item__edu"
            >
                <SchoolOutline />
                {{ writer.application.major.value }}
            </div>
            <div class="writers-details_item__writer-statistics">
                <div class="writers-details_item__writer-statistics-item">
                    <p class="writers-details_item__writer-statistics-title">
                        Completed projects
                    </p>
                    <p class="writers-details_item__writer-statistics-data">
                        <file />
                        {{ writer.statistics.orders_total | number }}
                    </p>
                </div>
                <div class="writers-details_item__writer-statistics-item">
                    <p class="writers-details_item__writer-statistics-title">
                        Success rating
                    </p>
                    <p class="writers-details_item__writer-statistics-data">
                        <score />
                        {{ ( writer.statistics.rank_all || 0 ) | percentWriterRank }}
                    </p>
                </div>
                <div class="writers-details_item__writer-statistics-item">
                    <p class="writers-details_item__writer-statistics-title">
                        Reviews count
                    </p>
                    <p class="writers-details_item__writer-statistics-data">
                        <file />
                        {{ writer.statistics.rank_all_count | number }}
                    </p>
                </div>
                <div class="writers-details_item__writer-statistics-item">
                    <p class="writers-details_item__writer-statistics-title">
                        Preferred count
                    </p>
                    <p class="writers-details_item__writer-statistics-data">
                        {{ writer.statistics.preferred_count | number }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filters-mixin.js'
import Avatar from '@/components/common/Avatar.vue'
import StarRating from 'vue-star-rating'
import SchoolOutline from 'mdi-vue/SchoolOutline.vue';
import File from '@/components/icons/File.vue';
import Score from '@/components/icons/Score.vue';

const Env = require('@/helpers/environment/index.js');

const EnvSettings = new Env(process.env.VUE_APP_DOMAIN);

export default {
    components: {
        Avatar,
        StarRating,
        SchoolOutline,
        File,
        Score
    },
    mixins: [filtersMixin],
    props: {
        writer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            themecolor: EnvSettings.styleSetting.color
        }
    },
    computed: {
        isWriterAccountDetails() {
            return this.$route.name === 'writer_account_reviews' || this.$route.name === 'writer_account_about'
        }
    }
}
</script>

<style lang="scss">
.mark_us_trusted {
    position: absolute;
    top: 20px;
    right: 0;
    padding: 5px 12px 5px 20px;
    background: url('~@/assets/img/svg/trusted_mark.svg') center / contain no-repeat;
    font-weight: 600;
    color: #fff
}

.writers-account-details_item {
    box-shadow: 0px 8px 48px -10px rgba(16, 24, 40, 0.18);
    display: flex;
    border-radius: 4px;
    background: #fff;
    font-size: 14px;
    @media (max-width: 992px) {
        margin-bottom: 20px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.writers-details{
    padding: 20px;
    &_item{
        box-shadow: 0px 8px 48px -10px rgba(16, 24, 40, 0.18);
        display: flex;
        border-radius: 4px;
        background: #fff;
        font-size: 14px;
        @media (max-width: 992px) {
            margin-bottom: 20px;
        }
        @media (max-width: 768px) {
            display: none;
        }
        &__info {
            position: relative;
            padding: 20px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        &__avatar{
            padding: 20px 0 20px 20px;
            min-width: 140px;
            width: 140px;
            position: relative;
            .avatar-img {
                width: 120px;
                height: 120px;
            }
            &_img{
                max-width: 100%;
                border-radius: 50%;
            }
            &--status{
                width: 16px;
                height: 16px;
                position: absolute;
                top: 28px;
                right: 8px;
                border-radius: 50%;
                border: 2px solid #fff;
                @media (max-width: 1024px) {
                    top: 24px;
                    right: 12px;
                }
                &.online {
                    background: #00b55d;
                }
                &.offline {
                    background: #B71A1A;
                }
            }
        }
        &__name{
            font-weight: 700;
            margin-bottom: 10px;
            font-size: 16px;
        }
        &__id{
            font-weight: 400;
            font-size: 14px;
        }
        &__lastseen {
            margin-bottom: 5px;
            padding: 6px 10px;
            background: #F2F6FF;
            border-radius: 999px;
            font-size: 14px;
            font-weight: 400;
            &-tooltip{
                border: 1px solid #1f2939;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                transition: all 0.1s ease-in;
                cursor: pointer;
            }
        }
        &__rating{
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .vue-star-rating-rating-text{
                color: $main-color;
                font-weight: 700;
                font-size: 14px;
            }
            &-count{
                margin-left: 10px;
            }
        }
        &__edu{
            display: flex;
            align-items: center;
            margin: 10px 0;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.4;
            svg {
                width: 20px;
                margin-right: 14px;
            }
        }
        &__count{
            margin-top: auto;
            span {
                text-decoration: underline;
            }
        }
        &__payment{
            text-align: center;
            margin: auto 0;
            &--big {
                font-weight: 700;
                font-size: 26px;
            }
            & > p {
                font-weight: 700;
                margin: 15px 0;
            }
        }
        &__writer {
            &-statistics {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 20px 0 0;
                border-top: 1px solid #EAEAEA;
                // gap: 130px;
                @media (max-width: 992px) {
                    gap: 20px;
                    flex-wrap: wrap;
                    &-item {
                        width: 45%;
                    }
                }
                &-title {
                    color: #61646A;
                    margin-bottom: 10px;
                }
                &-data {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    color: #111720;
                    font-weight: 700;
                    white-space: nowrap;
                    >svg {
                        margin-right: 16px;
                    }
                }
            }
        }
    }
}
</style>
