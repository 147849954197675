<template>
    <div
        v-if="showPage"
        class="offer-main__layout"
    >
        <entering-form-header />
        <div class="offer-main__layout-wrapper wrapper">
            <div class="offer-error">
                <div class="offer-error--title">
                    Something went wrong!
                </div>
                <div class="offer-error--inner">
                    please check your link
                </div>
                <custom-button
                    default
                    class="button btn-base_colored friends-banner__btn"
                    @on-btn-click="$router.push({ name: 'register' })"
                >
                    register
                </custom-button>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>

import EnteringFormHeader from '@/components/EnteringFormHeader';
import { eventBus } from '@/helpers/event-bus';

import { setPromoHash } from '@/services/promo-companies'

import {
    isHashExisting
} from '@/services/hash-checker'
import Footer from '@/components/Footer.vue';

export default {
    name: 'FriendMain',
    components: {
        EnteringFormHeader,
        Footer
    },
    data() {
        return {
            showPage: false
        }
    },
    async created() {
        await this.isHashExisting()
    },
    methods: {
        async isHashExisting() {
            try {
                const requestData = {
                    hash_offer: this.$route.params.hash
                }
                const is_valid = await isHashExisting(requestData)
                if (!is_valid) {
                    this.showPage = true
                    return
                }
                setPromoHash(this.$route.params.hash)
                this.$router.push({ name: 'register' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        }
    }
}
</script>

<style lang="scss">

.offer {
    &-main {
        &__layout {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
        &__layout-wrapper{
            margin-top: 30px;
            margin-bottom: 30px;
            display: flex;
            flex-grow: 1;
            flex-direction: column
        }
        &__wrapper{
            display: flex;
        }
    }
    &-error {
        margin: auto;
        min-width: 70%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        padding: 35px;
        border-radius: 10px;
        box-shadow: 0 2px 13px 6px rgba($color: $light-grey, $alpha: .1);
        @include media480max {
            padding: 15px;
        }

        &--title {
            text-align: center;
            font-size: 32px;
            font-weight: 700;
            @include media480max {
                font-size: 24px;
            }
        }
        &--inner {
            font-size: 24px;
            @include media480max {
                font-size: 18px;
            }
        }
    }
}

</style>
