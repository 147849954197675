<template>
    <div
        class="writers_item"
        :class="{
            'skeleton': skeleton
        }"
    >
        <div
            v-if="writer.is_trusted"
            class="mark_us_trusted"
        >
            Trusted
        </div>
        <div class="writers_item__avatar without_skeleton">
            <avatar
                :avatar-url="writer.avatar_url"
                :avatar-fallback="writer.avatar_fallback"
                :nickname="writer.name"
                :user="'writer'"
            />
            <span
                class="writers_item__avatar--status"
                :class="writer.is_online ? 'online' : 'offline'"
            />
        </div>
        <div class="writers_item__info without_skeleton">
            <p class="writers_item__name">
                {{ writer.name }}
                <span class="writers_item__id">
                    ID: {{ writer.id }}
                </span>
            </p>
            <span
                v-if="!writer.is_online && writer.last_seen"
                class="writers_item__lastseen"
            >
                Last seen: {{ writer.last_seen | moment_from }} ago
                <span
                    class="writers_item__lastseen-tooltip tooltip-zoom"
                    :data-tooltip-attribute="writer.last_seen | moment_short_text"
                >
                    i
                </span>
            </span>
            <div class="writers_item__rating">
                <star-rating
                    :star-size="14"
                    :increment="0.1"
                    :rating="writer.statistics.rank_all / 2"
                    :active-color="themecolor"
                    :read-only="true"
                />
                <p
                    class="writers_item__rating-count"
                    @click="openModalReview(writer.id)"
                >
                    based on {{ writer.statistics.rank_all_count | number }} {{ writer.statistics.rank_all_count > 1 ? 'reviews' : 'review' }}
                </p>
            </div>
            <div
                v-if="writer.application.major"
                class="writers_item__edu"
            >
                <SchoolOutline />
                {{ writer.application.major.value }}
            </div>
            <div
                v-if="writer.application.personal_statement"
                class="writers_item__data"
            >
                {{ writer.application.personal_statement.value }}
            </div>
        </div>
        <div class="writers_item__actions without_skeleton">
            <div class="writers_item__buttons without_skeleton">
                <a
                    :href="`/order/standard?writer=${writer.id}`"
                    class="btn-base btn-main"
                >
                    Hire expert
                </a>
                <router-link
                    :to="{
                        name: `${isWriterAccount ? 'writer_about' : 'writer_account_about'}`,
                        params: { id: writer.id }
                    }"
                    class="btn-base btn-border"
                >
                    view profile
                </router-link>
                <button
                    class="writers_item__buttons-more"
                    @click="showMore"
                >
                    <span>
                        <img
                            :src="require(`@/assets/img/svg/arrow.svg`)"
                            class="writers_item__buttons-more-arrow"
                            :class="{ 'active': showStatistics }"
                            alt="arrow"
                        >
                        Show more
                    </span>
                </button>
            </div>
        </div>
        <div
            class="writers_item__writer-statistics without_skeleton"
            :class="{ 'active': showStatistics }"
        >
            <div class="writers_item__writer-statistics-item">
                <p class="writers_item__writer-statistics-title">
                    Projects
                </p>
                <p
                    v-if="writer.statistics && writer.statistics.orders_total"
                    class="writers_item__writer-statistics-data"
                >
                    <file />
                    {{ writer.statistics.orders_total | number }}
                </p>
            </div>
            <div class="writers_item__writer-statistics-item">
                <p class="writers_item__writer-statistics-title">
                    Success rate
                </p>
                <p class="writers_item__writer-statistics-data">
                    <score />
                    {{ ( writer.statistics.rank_all || 0 ) | percentWriterRank }}
                </p>
            </div>
            <div class="writers_item__writer-statistics-item">
                <p class="writers_item__writer-statistics-title">
                    Reviews
                </p>
                <p class="writers_item__writer-statistics-data">
                    <file />
                    {{ writer.statistics.rank_all_count | number }}
                </p>
            </div>
            <div class="writers_item__writer-statistics-item">
                <p class="writers_item__writer-statistics-title">
                    Preferred
                </p>
                <p class="writers_item__writer-statistics-data">
                    {{ writer.statistics.preferred_count | number }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filters-mixin.js'
import Avatar from '@/components/common/Avatar.vue'
import StarRating from 'vue-star-rating'
import SchoolOutline from 'mdi-vue/SchoolOutline.vue';
import File from '@/components/icons/File.vue';
import Score from '@/components/icons/Score.vue';

const Env = require('@/helpers/environment/index.js');

const EnvSettings = new Env(process.env.VUE_APP_DOMAIN);

export default {
    components: {
        Avatar,
        StarRating,
        SchoolOutline,
        File,
        Score
    },
    mixins: [filtersMixin],
    props: {
        writer: {
            type: Object,
            required: true
        },
        skeleton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showStatistics: false
        }
    },
    computed: {
        themecolor() {
            return EnvSettings.styleSetting.color
        },
        isWriterAccount() {
            return this.$route.name !== 'writers-account'
        }
    },
    methods: {
        showMore() {
            this.showStatistics = !this.showStatistics
        },
        openModalReview(writerId) {
            this.$emit('openModalReview', writerId)
        }
    }
}
</script>

<style lang="scss">
.mark_us_trusted {
    position: absolute;
    top: 20px;
    right: 245px;
    padding: 5px 12px 5px 20px;
    background: url('~@/assets/img/svg/trusted_mark.svg') center / contain no-repeat;
    font-weight: 600;
    color: #fff;
    @media (max-width: 1024px) {
        right: -5px;
    }
    @include media480max {
        right: 0;
    }
    // @media (max-width: 568px) {
    //     margin: 15px 0;
    //     position: static;
    //     background: #1FB119;
    //     width: 100%;
    //     border-radius: 4px;
    //     text-align: center;
    // }
}
.writers{
    padding: 20px;
    &_item{
        box-shadow: 0px 8px 48px -10px rgba(16, 24, 40, 0.18);
        position: relative;
        display: grid;
        grid-template-areas: "avatar info actions"
            "avatar statistics actions"
        ;
        grid-template-columns: 170px 1fr 250px;
        margin-bottom: 20px;
        border-radius: 4px;
        background: #fff;
        font-size: 14px;
        @media (max-width: 1024px) {
            flex-wrap: wrap;
            grid-template-areas: "avatar info"
                "avatar statistics"
                "actions actions"
            ;
            grid-template-columns: 112px 1fr;
        }
        @media (max-width: 480px) {
            margin-bottom: 0px;
        }
        @media (max-width: 568px) {
            flex-direction: column;
            align-items: center;
            grid-template-areas: "avatar"
                "info"
                "actions"
                "statistics"
            ;
            justify-items: center;
            grid-template-columns: 1fr;
        }
        &__info {
            grid-area: info;
            position: relative;
            padding: 20px 0 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @media (max-width: 1024px) {
                padding-right: 20px;
                max-width: calc(100% - 112px);
            }
            @media (max-width: 568px) {
                margin-top: 10px;
                padding: 0 20px;
                max-width: initial;
                width: 100%;
                align-items: center;
            }
        }
        &__avatar{
            grid-area: avatar;
            padding: 20px 0 20px 20px;
            margin-right: 30px;
            position: relative;
            .avatar-img {
                width: 120px;
                height: 120px;
            }
            @media (max-width: 1024px) {
                margin-right: 20px;
                .avatar-img {
                    width: 72px;
                    height: 72px;
                }
            }
            @media (max-width: 568px) {
                padding: 20px 0 0;
                margin-right: 0;
            }
            &_img{
                max-width: 100%;
                border-radius: 50%;
            }
            &--status{
                width: 16px;
                height: 16px;
                position: absolute;
                top: 28px;
                right: 8px;
                border-radius: 50%;
                border: 2px solid #fff;
                @media (max-width: 1024px) {
                    top: 21px;
                    right: 3px;
                }
                &.online {
                    background: #00b55d;
                }
                &.offline {
                    background: #B71A1A;
                }
            }
        }
        &__name{
            font-weight: 700;
            margin-bottom: 10px;
            font-size: 16px;
        }
        &__lastseen {
            margin-bottom: 5px;
            padding: 6px 10px;
            background: #F2F6FF;
            border-radius: 999px;
            font-size: 14px;
            font-weight: 400;
            &-tooltip{
                border: 1px solid #1f2939;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                transition: all 0.1s ease-in;
                cursor: pointer;
            }
        }
        &__id{
            font-weight: 400;
            font-size: 14px;
        }
        &__rating{
            display: flex;
            margin-bottom: 5px;
            &-count{
                margin-left: 10px;
                text-decoration: underline;
                cursor: pointer;
                &:hover{
                    color: $main-color;
                }
            }
        }
        &__edu{
            display: -webkit-box;
            align-items: baseline;
            margin-bottom: 19px;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.4;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            svg {
                width: 20px;
                margin-right: 14px;
            }
        }
        &__data {
            margin-bottom: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &__count{
            margin-top: auto;
            span {
                text-decoration: underline;
            }
        }
        &__actions{
            grid-area: actions;
            padding: 20px;
            min-width: 230px;
            margin-left: 20px;
            display: flex;
            background: #fafafa;
            @media (max-width: 1024px) {
                width: 100%;
                margin-left: 0;
            }
        }
        &__payment{
            text-align: center;
            margin: auto 0;
            &--big {
                font-weight: 700;
                font-size: 26px;
            }
            & > p {
                font-weight: 700;
                margin: 15px 0;
            }
        }
        &__buttons{
            margin: auto;
            display: flex;
            flex-direction: column;
            @media (max-width: 1024px) {
                flex-direction: row;
                gap: 12px;
                justify-content: center;
            }
            @media (max-width: 576px) {
                flex-wrap: wrap;
            }
            &-more {
                position: relative;
                display: none;
                width: 100%;
                text-align: center;
                align-items: center;
                justify-content: center;
                padding: 14px 0;
                background: transparent;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #111720;
                border: none;
                @media (max-width: 576px) {
                    display: flex;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 35px;
                    height: 1px;
                    background: #EAEAEA;
                }

                >span {
                    padding: 0 12px;
                    display: flex;
                    align-items: center;
                    gap: 18px;
                    background: #FAFAFA;
                    z-index: 1;
                }
                &-arrow {
                    transform: rotate(-90deg);
                    width: 12px;
                    transition: transform 0.2s;
                    &.active {
                        display: flex;
                        transform: rotate(90deg);
                    }
                }
            }
            .btn-base{
                @media (max-width: 576px) {
                    padding: 12px 22px;
                }
                &:first-child {
                    margin-bottom: 10px;
                }
                svg {
                    margin-right: 10px;
                }
            }
        }
        &__writer {
            &-statistics {
                grid-area: statistics;
                display: flex;
                width: 100%;
                padding: 20px 0;
                border-top: 1px solid #EAEAEA;
                justify-content: space-between;
                gap: 20px;
                @media (max-width: 1024px) {
                    max-width: 600px;
                    padding-right: 20px;
                }
                @media (max-width: 576px) {
                    flex-direction: column;
                    transition: all .2s;
                    padding: 0 20px;
                    height: 0;
                    opacity: 0;
                    overflow: hidden;
                }
                &.active {
                    display: flex;
                    padding: 20px;
                    opacity: 1;
                    height: auto;
                }
                &-title {
                    color: #61646A;
                    margin-bottom: 10px;
                }
                &-data {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    color: #111720;
                    font-weight: 700;
                    >svg {
                        margin-right: 16px;
                    }
                }
            }
        }
    }
}
</style>
