<template>
    <div
        v-if="showPage"
        class="friend-main__layout"
    >
        <entering-form-header />
        <div class="friend-main__layout-wrapper wrapper">
            <div class="friends-banner">
                <span class="friends-banner__title">
                    Get your
                </span>
                <span class="friends-banner__credit">
                    $25 CREDIT
                </span>
                <span class="friends-banner__try">
                    to try our service
                </span>
                <span class="friends-banner__free">
                    FOR FREE
                </span>
                <custom-button
                    default
                    class="button btn-base_colored friends-banner__btn"
                    @on-btn-click="$router.push({ name: 'register' })"
                >
                    get now
                </custom-button>
            </div>
            <div class="friend-main__wrapper">
                <div
                    class="friend-main__steps"
                    :class="{'additional-image-exist': isFindDomain}"
                >
                    <ul class="friend-main__steps-block">
                        <li
                            v-for="(card, index) in Cards"
                            :key="index"
                            class="friend-main__steps-block-item"
                        >
                            <div class="friend-main__steps-block-item-icon">
                                <component :is="card.icon" />
                            </div>
                            <div>
                                <div class="friend-main__steps-block-item-info">
                                    <span style="margin-right: 10px">{{ index + 1 }}.</span>
                                    <span>{{ card.title }}</span>
                                </div>
                                <div
                                    class="friend-main__steps-block-item-description"
                                >
                                    {{ card.description }}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <custom-button
                        default
                        class="button btn-base_colored"
                        @on-btn-click="$router.push({ name: 'register' })"
                    >
                        Register
                    </custom-button>
                </div>
                <FriendsMainSidebar />
            </div>
        </div>
    </div>
</template>

<script>

import EnteringFormHeader from '@/components/EnteringFormHeader';
import FriendsMainSidebar from '@/views/friends-main/FriendsMainSidebar.vue';
import { eventBus } from '@/helpers/event-bus';

import {
    setReferralHash
} from '@/services/referral'

import {
    isHashExisting
} from '@/services/hash-checker'

import { isFindDomain } from '@/services/check-domain'

// icons

import User from '@/components/icons/friends/User.vue';
import Balance from '@/components/icons/friends/Balance.vue';
import CheckOrder from '@/components/icons/friends/CheckOrder.vue';
import LoadingOrder from '@/components/icons/friends/LoadingOrder.vue';
import FinishOrder from '@/components/icons/friends/FinishOrder.vue';
import Tell from '@/components/icons/friends/Tell.vue';

export default {
    name: 'FriendMain',
    components: {
        EnteringFormHeader,
        FriendsMainSidebar
    },
    data() {
        return {
            showPage: false
        }
    },
    computed: {
        Cards() {
            return [
                {
                    icon: User,
                    title: 'Register in seconds',
                    description: 'Register in our application with your e-mail and password or your social network in just a few clicks.'
                },
                {
                    icon: Balance,
                    title: 'Get $25.00',
                    description: 'You will automatically be credited with $25.00 USD to spend on your first order.'
                },
                {
                    icon: CheckOrder,
                    title: 'Order for free',
                    description: 'You can order a one or two page assignment to test our service free of charge.'
                },
                {
                    icon: LoadingOrder,
                    title: 'Get your project done by your personal writer',
                    description: 'One of our writers will be assigned to complete your project by your deadline. You can talk to the writer and send additional resources.'
                },

                {
                    icon: FinishOrder,
                    title: 'Receive the finished paper',
                    description: 'That`s it, your order is ready. You will get your file in the e-mail and can download it on our site as well.'
                },
                {
                    icon: Tell,
                    title: 'Tell your friends about our amazing service and come back for more!',
                    description: 'We will hope to see you again!'
                }
            ]
        },
        isFindDomain
    },
    async created() {
        await this.isHashExisting()
    },
    methods: {
        async isHashExisting() {
            try {
                const requestData = {
                    hash_invite: this.$route.params.referral_hash
                }
                const is_valid = await isHashExisting(requestData)
                if (!is_valid) {
                    this.$router.push({ name: '404' })
                    return
                }
                setReferralHash(this.$route.params.referral_hash)
                this.showPage = true
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        }
    }
}
</script>

<style lang="scss">

.friend-main {
    &__layout{
        //height: 100vh;
    }
    &__layout-wrapper{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    &__wrapper{
        display: flex;
    }
    &__steps{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: normal;
        @include media992max {
            width: 100%;
            //padding: 60px;
        }
    }
    &__steps-block{
        margin-bottom: 30px;
    }
    &__steps-block-item{
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 40px;
        border-radius: 4px;
        box-shadow: 0px 5px 15px 1px rgb(0 0 0 / 8%);
        background: #f9f9f9;
        transition: box-shadow 0.2s;
        &:first-child {
            margin-top: 40px;
        }
    }
    &__steps-block-item-icon{
        background: rgba($main-color, 0.1);
        padding: 15px;
        border-radius: 50%;
        margin-right: 20px;
    }
    &__steps-block-item-info{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    &__steps-block-item-description{
        line-height: 1.3;
        color: rgba(0, 0, 0, 0.6);
    }
}

.friends-banner{
    background: rgba($main-color, 0.1);
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: auto;
    padding: 35px 15px;
    text-align: center;
    &__title, &__try{
        white-space: nowrap;
        font: 900 32px/53px Inter,sans-serif;
        padding: 0 10px;
        @include media480max {
            font: 900 22px/43px Inter,sans-serif;
        }
    }
    &__credit, &__free{
        white-space: nowrap;
        color: $main-color;
        font: 900 42px/53px Inter,sans-serif;
        padding: 0 10px;
        @include media480max {
            font: 900 32px/43px Inter,sans-serif;
        }
    }
}
</style>
