<template>
    <nav>
        <ul class="writer-card-details-tabs">
            <template v-for="item in items">
                <li
                    v-if="!item.hide"
                    :key="item.value"
                    class="button transparent-btn"
                    :class="{'active-tab-link': $route.name === item.value}"
                    @click.prevent="redirectToTab(item.value)"
                >
                    <component :is="item.icon" />
                    <span class="order-tab-text">
                        {{ item.text }}
                    </span>
                    <span
                        v-if="item.counter !== false"
                        class="order-tab-counter"
                    >
                        {{ item.counter }}
                    </span>
                </li>
            </template>
        </ul>
    </nav>
</template>

<script>

import AboutWriter from '@/components/icons/writer-tabs/AboutWriter'
import Reviews from '@/components/icons/writer-tabs/Reviews'

export default {
    name: 'WriterCardDetailsTabs',
    components: {
        AboutWriter,
        Reviews
    },
    props: {
        items: {
            type: Array
        }
    },
    methods: {
        redirectToTab(value) {
            this.$emit('redirectToTab', value)
        }
    }
}
</script>

<style lang="scss">

.writer-card-details-tabs{
    box-shadow: inset 0px -1px 0 0 #e7ecf4;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #efefef;
    background-color: #FFFFFF;
    margin-top: 20px;
    @media screen and (max-width: 992px) {
        display: none;
    }
    .transparent-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        //max-width: 25%;
        min-height: 58px;
        border: 0;
        text-transform: none;
        font-size: 16px;
        line-height: 1.06;
        color: #1f2939;
        font-weight: 500;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .active-tab-link {
        background-color: #fff;
        box-shadow: 0px -5px 15px rgb(0 0 0 / 12%);
        border-bottom: 1px solid #e4e9f1;
        position: relative;
        outline: none;
        &:before {
            content: "";
            position: absolute;
            display: block;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 4px;
            background-image: $main-gradient;
            box-shadow: inset 0px -1px 0 0 rgb(50 61 79 / 6%);
        }
    }
}

</style>
